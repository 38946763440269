import React from 'react';

// Define an object that maps each product to its shades with prices
const sharedShadesSoffitDuo = [
    { name: 'Wooden Collection - Pine wood', price: 185, jTrimPrice: 70, TtrimPrice: 165 },
    { name: 'Wooden Collection - Maple wood', price: 185, jTrimPrice: 70, TtrimPrice: 165 },
    { name: 'Wooden Collection - Birch', price: 185, jTrimPrice: 70, TtrimPrice: 165 },
    { name: 'Wooden Collection - Walnut', price: 185, jTrimPrice: 70, TtrimPrice: 165 },
    { name: 'Wooden Collection - Golden Oak', price: 185, jTrimPrice: 70, TtrimPrice: 165 },
    { name: 'Wooden Collection - Weathered Grey', price: 185, jTrimPrice: 70, TtrimPrice: 165 },
    { name: 'Uni Collection - Super White', price: 185, jTrimPrice: 70, TtrimPrice: 165 },
    { name: 'Uni Collection - Graphite', price: 185, jTrimPrice: 70, TtrimPrice: 165 },
    { name: 'Forest Collection - Mahogany', price: 205, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Forest Collection - Chestnut', price: 205, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Forest Collection - Oak Wood Ocre', price: 205, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Forest Collection - Royal Nut', price: 205, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Forest Collection - Ash Wood Grey', price: 205, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Forest Collection - Spiced Walnut', price: 205, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Real Wood Collection - Artisan Oak', price: 245, jTrimPrice: 92, TtrimPrice: 225 },
    { name: 'Real Wood Collection - Imperial Walnut', price: 245, jTrimPrice: 92, TtrimPrice: 225 },
  ];

  const sharedShadesLouvers = [
    { name: 'Forest Collection - Mahogany', price: 255, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Forest Collection - Oak Wood Ocre', price: 255, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Forest Collection - Ash Wood Grey', price: 255, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Forest Collection - Beech Wood', price: 255, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Forest Collection - Royal Nut', price: 255, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Forest Collection - Spiced Walnut', price: 255, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Marble Collection - Staturio', price: 255, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Marble Collection - Armani Grey', price: 255, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Marble Collection - Portoro', price: 255, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Fabric Collection - Pearl', price: 255, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Metallic Collection - Gold', price: 265, jTrimPrice: 84, TtrimPrice: 204 },
    { name: 'Metallic Collection - Silver', price: 265, jTrimPrice: 84, TtrimPrice: 204 },
    { name: 'Metallic Collection - Copper', price: 265, jTrimPrice: 84, TtrimPrice: 204 },
  ]

  const sharedShadesInnov2 = [
    { name: 'Wooden Collection - Honey Maple', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - Danish Teak', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - Oak Noir', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - White Mist', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - Noce Grandae', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - Gold Maple', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - English Nut', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - Oak Wood Ocre', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - Spiced Walnut', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Armani Grey', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Portoro', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Royal Staturio', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Armani Creama', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Fabric Collection - Linen Ivory', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Fabric Collection - Linen Grey', price: 250, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Metallic Collection - Argento', price: 262, jTrimPrice: 85, TtrimPrice: 205 },
    { name: 'Metallic Collection - Oro', price: 262, jTrimPrice: 85, TtrimPrice: 205 },
    { name: 'Metallic Collection - Rame', price: 262, jTrimPrice: 85, TtrimPrice: 205 },
    { name: 'Dual Black Wooden Collection - Honey Maple-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Wooden Collection - Danish Teak-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Wooden Collection - Oak Noir-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Wooden Collection - White Mist-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Wooden Collection - Noce Grande-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Wooden Collection - Gold Maple-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Wooden Collection - English Nut-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Wooden Collection - Oak Wood Ocre-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Wooden Collection - Spiced Walnut-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Marble Collection - Armani Grey-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Marble Collection - Portoro-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Marble Collection - Royal Staturio-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Marble Collection - Armani Creama-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Fabric Collection - Linen Ivory-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Fabric Collection - Linen Grey-DB', price: 280, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Metallic Collection - Argento-DB', price: 300, jTrimPrice: 85, TtrimPrice: 205 },
    { name: 'Dual Black Metallic Collection - Oro-DB', price: 300, jTrimPrice: 85, TtrimPrice: 205 },
    { name: 'Dual Black Metallic Collection - Rame-DB', price: 300, jTrimPrice: 85, TtrimPrice: 205 },
  ]

  const sharedShadesEasy = [
    { name: 'Forest Collection - Beach Wood', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Royal Nut', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Spiced Walnut', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Mahogany', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Oak Wood Ocre', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Ashwood Grey', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Staturio', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Armani Grey', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Portoro', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Fabric Collection - Pearl', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Metallic Collection - Gold ', price: 246, jTrimPrice: 89, TtrimPrice: 210 },
    { name: 'Metallic Collection - Silver', price: 246, jTrimPrice: 89, TtrimPrice: 210 },
    { name: 'Metallic Collection - Copper', price: 246, jTrimPrice: 89, TtrimPrice: 210 },
    { name: 'Pastel Collection - Snow White', price: 260, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection - Steel Blue', price: 260, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection - Mocha', price: 260, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection - Sky Blue', price: 260, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection - Forest Green', price: 260, jTrimPrice: 88, TtrimPrice: 210 },
    { name: 'Pastel Collection - Light Grey', price: 260, jTrimPrice: 88, TtrimPrice: 210 },
    { name: 'Pastel Collection - Cloud Grey', price: 260, jTrimPrice: 88, TtrimPrice: 210 },
    { name: 'Pastel Collection - Mint Green', price: 260, jTrimPrice: 88, TtrimPrice: 210 },
    { name: 'Dual Black Forest Collection - Beach Wood-DB', price: 268, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Forest Collection - Royal Nut-DB', price: 268, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Forest Collection - Spiced Walnut-DB', price: 268, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Forest Collection - Mahogany-DB', price: 268, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Forest Collection - Oak Wood Ocre-DB', price: 268, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Forest Collection - Ashwood Grey-DB', price: 268, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Marble Collection - Staturio-DB', price: 268, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Marble Collection - Armani Grey-DB', price: 268, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Dual Black Metallic Collection - Gold-DB', price: 280, jTrimPrice: 89, TtrimPrice: 210 },
    { name: 'Dual Black Metallic Collection - Silver-DB', price: 280, jTrimPrice: 89, TtrimPrice: 210 },
    { name: 'Dual Black Metallic Collection - Copper-DB', price: 280, jTrimPrice: 89, TtrimPrice: 210 },
  ]

  const sharedShadesPyramid = [
    { name: 'Forest Collection - Beach Wood', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Royal Nut', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Spiced Walnut', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Mahogany', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Oak Wood Ocre', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Ashwood Grey', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Staturio', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Armani Grey', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Portoro', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Fabric Collection - Pearl', price: 242, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Metallic Collection - Gold ', price: 246, jTrimPrice: 89, TtrimPrice: 210 },
    { name: 'Metallic Collection - Silver', price: 246, jTrimPrice: 89, TtrimPrice: 210 },
    { name: 'Metallic Collection - Copper', price: 246, jTrimPrice: 89, TtrimPrice: 210 },
    { name: 'Pastel Collection - Snow White', price: 260, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection - Steel Blue', price: 260, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection - Mocha', price: 260, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection - Sky Blue', price: 260, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection - Forest Green', price: 260, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection - Light Grey', price: 260, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection - Cloud Grey', price: 260, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection - Mint Green', price: 260, jTrimPrice: 88, TtrimPrice: 200 },
  ]

  const sharedShadesDura = [
    { name: 'Artisan Oak', price: 270, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Imperial Walnut', price: 270, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Dark Teak', price: 270, jTrimPrice: 75, TtrimPrice: 183 },
    { name: 'Larch', price: 270, jTrimPrice: 75, TtrimPrice: 183 },
  ] 

const shadesByProduct = {
  Soffit: sharedShadesSoffitDuo,
  Soffit2: sharedShadesSoffitDuo,
  Duo: sharedShadesSoffitDuo,
  Duo2: sharedShadesSoffitDuo,
  Vertica: [
    { name: 'Forest Collection - Beach Wood', price: 260, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Royal Nut', price: 260, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Spiced Walnut', price: 260, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Mahoany', price: 260, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Oak Wood Ocre', price: 260, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Forest Collection - Ashwood Grey', price: 260, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Staturio', price: 260, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Armani Grey', price: 260, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Portoro', price: 260, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Fabric Collection - Pearl', price: 260, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Metallic Collection - Gold', price: 265, jTrimPrice: 89, TtrimPrice: 210 },
    { name: 'Metallic Collection - Silver', price: 265, jTrimPrice: 89, TtrimPrice: 210 },
    { name: 'Metallic Collection - Copper', price: 265, jTrimPrice: 89, TtrimPrice: 210 },
  ],
  Louvers: sharedShadesLouvers,
  Louvers2: sharedShadesLouvers,
  Wave : [
    { name: 'Wooden Collection - Oak Wood Ocre', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - Spiced Walnut', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - Honey Maple', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - Danish Teak', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - Oak Noir', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - White Mist', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - Noce Grande', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - Gold Maple', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Wooden Collection - English Nut', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Armani Grey', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Portoro', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Marble Collection - Royal Staturio', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Fabric Collection  - Linen Ivory', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Fabric Collection  - Linen Grey', price: 205, jTrimPrice: 79, TtrimPrice: 192 },
    { name: 'Pastel Collection  - Snow White', price: 226, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection  - Steel Blue', price: 226, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection  - Mocha', price: 226, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection  - Sky Blue', price: 226, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection  - Forest Green', price: 226, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection  - Light Grey', price: 226, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection  - Cloud Grey', price: 226, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Pastel Collection  - Mint Green', price: 226, jTrimPrice: 88, TtrimPrice: 200 },
    { name: 'Metallic Collection  - Argento', price: 216, jTrimPrice: 85, TtrimPrice: 205 },
    { name: 'Metallic Collection  - Oro', price: 216, jTrimPrice: 85, TtrimPrice: 205 },
    { name: 'Metallic Collection  - Rame', price: 216, jTrimPrice: 85, TtrimPrice: 205 },
  ],
  Dome : sharedShadesInnov2,
  Prism : sharedShadesInnov2,
  Stripes : sharedShadesInnov2,
  Unic : sharedShadesInnov2,
  Linea : sharedShadesEasy,
  Arch : sharedShadesEasy,
  Pyramid : sharedShadesPyramid,
  Norma : sharedShadesDura,
  Streta : sharedShadesDura,
  // Add more products and shades as needed
};


const ShadesDropdown = ({ product, selectedShade, onSelectShade, selectedCategory }) => {
  const shades = shadesByProduct[product] || []; // Get shades for the selected product

  return (
    <div className="shades-dropdown">
        <label>Shades<span style={{ color: '#BE1E2E' }}>*</span>:</label>
        <select
          value={selectedShade ? selectedShade.name : ''}
          onChange={(e) => onSelectShade(shades.find(shade => shade.name === e.target.value))}
          disabled={!product} // Disable if no product is selected
        >
        <option value="">Select Shade</option>
        
        {shades.map((shade, index) => (
          <option key={index} value={shade.name}>
            {shade.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ShadesDropdown;
