export const lineaHorizontalPanelCount = (length, breadth, panelLength, panelBreadth) => { 

  // console.log("Length: "+length);
  // console.log("Breadth: "+ breadth);
  // console.log("Panel Breadth: "+panelBreadth)

  if(length <= 10){
    if (length <= 5 && breadth <= 5) {
      return Math.ceil((length * breadth) / (panelLength * panelBreadth));
    }else if (length === 7 && breadth === 5) {
      return Math.ceil(breadth / panelBreadth);
    }else {
      const initialPanelRequired = breadth / panelBreadth;
      const cuttingPanel = Math.floor(panelLength / length);
      return Math.ceil(initialPanelRequired / cuttingPanel);
  }
}else{
    // console.log("Length: "+length);
    // console.log("Breadth: "+ breadth);
    // console.log("Panel Breadth: "+panelBreadth)
    const fullHorizontalItems = Math.floor(length / panelLength);
    // console.log("Full Horizontal Items: " +fullHorizontalItems);
    const fullVerticalItems = Math.ceil(breadth / panelBreadth);
    // console.log("Full Vertical Items: " +fullVerticalItems)
    const fullpanelLengths = fullHorizontalItems * fullVerticalItems;
    
    // Handle the remaining length
    const remainingLength = Math.ceil(length % panelLength);
    // console.log("Remaining Length: " +remainingLength);
    let additionalpanelLengths = 0;
    if (remainingLength > 0 && remainingLength <= 5) {
      const cuttingPanelWithRemainingLength = Math.floor(panelLength/remainingLength)
      const dividelenghtItems = Math.ceil(breadth/panelBreadth);
      additionalpanelLengths =  Math.ceil(dividelenghtItems/cuttingPanelWithRemainingLength);
    }else if(remainingLength > 5){
      additionalpanelLengths = Math.ceil(breadth/panelBreadth);
    }//else if(remainingLength === 5){ 
    //   additionalpanelLengths = breadth/2;
    // }
    // console.log("Additional Panel Length: " +additionalpanelLengths);
    const totalItems = fullpanelLengths + Number(additionalpanelLengths);
    return totalItems;
    }
  }
  

export const lineaVerticalPanelCount = (length, breadthOfRoom, panelLength, panelBreadth) =>{
  if(breadthOfRoom === 8.999999999999998){
     var breadth = Math.ceil(breadthOfRoom)
  }else{
    breadth = breadthOfRoom;
  }
  if (length <= 5 && breadth <= 5) {
      const initialPanelRequired = length / panelBreadth;
      const cuttingPanel = Math.floor(panelLength / breadth);
      return Math.ceil(initialPanelRequired / cuttingPanel);
  } else if (length === 7 && breadth === 5) {
    return Math.ceil(length / panelLength);
  } 
  else {
      // console.log("Vertical Line Function")
      // console.log("Length: " +length);
      // console.log("breadth: " +breadth);
      // console.log("Panel Length: " +panelLength);
      const fullHorizontalItems = Math.floor(length / panelLength);
      // console.log("Full Horizontal Items: "+fullHorizontalItems);
      const fullVerticalItems = Math.ceil(breadth / panelBreadth);
      // console.log("Full Vertical Items: " +fullVerticalItems)
      const fullpanelLengths = fullHorizontalItems * fullVerticalItems;
      // Handle the remaining length
      const remainingLength = length % panelLength;
    
      // console.log("Remaining Length: " +remainingLength);
      let additionalpanelLengths = 0;
      if (remainingLength > 0 && remainingLength <= 5) {
        const cuttingPanelWithRemainingLength = Math.floor(panelLength/remainingLength)
        const dividelenghtItems = Math.ceil(breadth/panelBreadth);
        // console.log("Cutting Panel With Remaining Length: "+cuttingPanelWithRemainingLength)
        // console.log("Divided Length Items: " +dividelenghtItems)
        additionalpanelLengths = Math.ceil(dividelenghtItems/cuttingPanelWithRemainingLength);
      }else if(remainingLength > 5){
        additionalpanelLengths = Math.ceil(breadth/panelBreadth);
      }//else if(remainingLength === 5){ 
      //   additionalpanelLengths = breadth/2;
      //} 
      // console.log("Additional Panel Length: " +additionalpanelLengths);
      const totalItems = fullpanelLengths + Number(additionalpanelLengths);
      return totalItems;
  }
}