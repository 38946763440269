import React, { useEffect, useState } from 'react';
import './Calculator.css'; // Import the CSS file
import { JtrimsFormula, TtrimsFormula, TtrimsVerticalFormula } from './TrimsCalculation';
import { lineaHorizontalPanelCount, lineaVerticalPanelCount } from './utils/lineaUtils';
import { calculateDuraTotalItemsHorizontal, calculateDuraTotalItemsVertical } from './utils/duraUtils';
import { calculateStretaHorizontalPanelCount, calculateStretaVerticalPanelCount } from './utils/duraStretaUtils';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { HorizontalWastageOfPanel, VerticalWastageOfPanel } from './utils/CalculateWastagePanels';
import { VerticalPanelCountInMM, horizontalPanelCountInMM } from './utils/lineaUtilsCalculationInMM';
import FeetInchesToMMConverter from './FeetInchesToMMConverter';
import { BafflehorizontalPanelCountInMM, BaffleverticalPanelCountInMM } from './utils/BaffleUtils';
import { BaffleCapHorizontal, BaffleCapVertical } from './BaffleCapCalculation';
import ShadesDropdown from './ShadesPricing/ShadesDropdown';
import { JtrimsFormulaDura, TtrimsFormulaDura, TtrimsVerticalFormulaDura } from './DuraTrimsCalculation';
import indianStates from './utils/IndianStates';
import BaffleOptions from './ShadesPricing/CategoryDropdownForBaffle';
import DimensionsInput from './UserDimensionsInput';
import WallInput from './WallInput';
import TableComponent from './TableResults';
import { VerticalSoffitLargerPanelCountInMM, horizontalSoffitLargerPanelCountInMM } from './utils/SoffitLargerPanelsLargerUtils';



const mmToFeet = (mm) => {
  return (mm / 306).toFixed(2); // 1 foot = 304.8 mm
};  

const lineammToFeet = (mm) => {
  return (mm / 304.8).toFixed(2); // 1 foot = 304.8 mm
};

const TtrimsLength = 10;
const TtrimsLengthDura = 12;


const productsConfigInMM = {
  Soffit: {
    name: 'Soffit (3060 x 300mm)',
    panelLength: 3060,
    panelBreadth: 300,
    perPcSqFt: 9.88,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Soffit2: {
    name: 'Soffit (4000 x 300mm)',
    panelLength: 4000,
    panelBreadth: 300,
    perPcSqFt: 12.92,
    JTrimperPcSqFt: 13.12,
    TTrimperPcSqFt: 13.12,
  },
  Duo: {
    name: 'Duo (3060 x 300mm)',
    panelLength: 3060,
    panelBreadth: 300,
    perPcSqFt: 9.88,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Duo2: {
    name: 'Duo (4000 x 300mm)',
    panelLength: 4000,
    panelBreadth: 300,
    perPcSqFt: 12.92,
    JTrimperPcSqFt: 13.12,
    TTrimperPcSqFt: 13.12,
  },
  Vertica: {
    name: 'Vertica (3060 x 210mm)',
    panelLength: 3060,
    panelBreadth: 210,
    perPcSqFt: 6.92,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Louvers: {
    name: 'Louvers (3060 x 225mm)',
    panelLength: 3060,
    panelBreadth: 225,
    perPcSqFt: 7.41,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Louvers2: {
    name: 'Louvers (4000 x 225mm)',
    panelLength: 4000,
    panelBreadth: 225,
    perPcSqFt: 9.69,
    JTrimperPcSqFt: 13.12,
    TTrimperPcSqFt: 13.12,
  },
  Wave: {
    name: 'Wave (3060 x 240mm)',
    panelLength: 3060,
    panelBreadth: 240,
    perPcSqFt: 7.90,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Dome: {
    name: 'Dome (3060 x 245mm)',
    panelLength: 3060,
    panelBreadth: 245,
    // panelBreadthForPrice: 290,
    perPcSqFt: 9.55,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Prism: {
    name: 'Prism (3060 x 228mm)',
    panelLength: 3060,
    panelBreadth: 228,
    // panelBreadthForPrice: 260,
    perPcSqFt: 8.56,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Stripes: {
    name: 'Stripes (3060 x 180mm)',
    panelLength: 3060,
    panelBreadth: 180,
    // panelBreadthForPrice: 215,
    perPcSqFt: 7.08,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Unic: {
    name: 'Unic (3060 x 240mm)',
    panelLength: 3060,
    panelBreadth: 240,
    // panelBreadthForPrice: 270,
    perPcSqFt: 8.89,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Linea: {
    name: 'Linea (3060 x 115mm)',
    panelLength: 3060,
    panelBreadth: 115,
    // panelBreadthForPrice: 150,
    perPcSqFt: 4.94,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Pyramid: {
    name: 'Pyramid (3060 x 120mm)',
    panelLength: 3060,
    panelBreadth: 120,
    // panelBreadthForPrice: 140,
    perPcSqFt: 4.61,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Arch: {
    name: 'Arch (3060 x 150mm)',
    panelLength: 3060,
    panelBreadth: 150,
    // panelBreadthForPrice: 180,
    perPcSqFt: 5.93,
    JTrimperPcSqFt: 10.04,
    TTrimperPcSqFt: 10.04,
  },
  Norma: {
    name: 'Norma (3660 x 300mm)',
    panelLength: 3660,
    panelBreadth: 300,
    perPcSqFt: 11.81,
    JTrimperPcSqFt: 12.005,
    TTrimperPcSqFt: 12.005,
  },
  Streta: {
    name: 'Streta (3660 x 225mm)',
    panelLength: 3660,
    panelBreadth: 225,
    perPcSqFt: 8.86,
    JTrimperPcSqFt: 12.005,
    TTrimperPcSqFt: 12.005,
  },
  Baffle: {
    name: 'Baffle',
    panelLength: 3060,
    panelBreadth: 50,
    perPcSqFt: 10.04
  }
};

const productsConfig = {
  Soffit: {
    name: 'Soffit (3060 x 300mm)',
    panelLengthFt: 10.04, // Length of each soffit panel in feet
    panelBreadth: 1, // Breadth of each soffit panel in feet
  },
  Soffit2: {
    name: 'Soffit (4000 x 300mm)',
    panelLengthFt: 13.12,
    panelBreadth: 1,
  },
  Duo: {
    name: 'Duo (3060 x 300mm)',
    panelLengthFt: 10.04,
    panelBreadth: 1,
  },
  Duo2: {
    name: 'Duo (4000 x 300mm)',
    panelLengthFt: 13.12,
    panelBreadth: 1,
  },
  Vertica: {
    name: 'Vertica (3060 x 210mm)',
    panelLength: 10.04, // Length of each vertica panel in feet
    panelBreadth: 0.69, // Breadth of each vertica panel in feet
  },
  Louvers: {
    name: 'Louvers (3060 x 225mm)',
    panelLength: 10.04, // Length of each louvers panel in feet
    panelBreadth: 0.74, // Breadth of each louvers panel in feet
  },
  Louvers2: {
    name: 'Louvers (4000 x 225mm)',
    panelLength: 13.12,
    panelBreadth: 0.74,
  },
  Wave: {
    name: 'Wave (3060 x 240mm)',
    panelLength: 10.04,
    panelBreadth: 0.79,
  },
  Dome: {
    name: 'Dome (3060 x 245mm)',
    panelLength: 10.04,
    panelBreadth: 0.80,
  },
  Prism: {
    name: 'Prism (3060 x 228mm)',
    panelLength: 10.04,
    panelBreadth: 0.75,
  },
  Stripes: {
    name: 'Stripes (3060 x 180mm)',
    panelLength: 10.04,
    panelBreadth: 0.59,
  },
  Unic: {
    name: 'Unic (3060 x 240mm)',
    panelLength: 10.04,
    panelBreadth: 0.79,
  },
  Linea: {
    name: 'Linea (3060 x 115mm)',
    panelLength: 10.04,
    panelBreadth: 0.38,
  },
  Pyramid: {
    name: 'Pyramid (3060 x 120mm)',
    panelLength: 10.04,
    panelBreadth: 0.39,
  },
  Arch: {
    name: 'Arch (3060 x 150mm)',
    panelLength: 10.04,
    panelBreadth: 0.49,
  },
  Norma: {
    name: 'Norma (3660 x 300mm)',
    panelLengthFt: 12,
    panelBreadth: 1,
  },
  Streta: {
    name: 'Streta (3660 x 225mm)',
    panelLengthFt: 12,
    panelBreadth: 0.74
  },
  Baffle: {
    name: 'Baffle',
    panelLength: 10.04,
    panelBreadth: 0.16
  }
};

const Calculator = () => {
  const [name, setName] = useState(''); // State for Name
  const [city, setCity] = useState(''); // State for City
  const [state, setState] = useState(''); // State for State
  const [phoneNumber, setPhoneNumber] = useState(''); // State for Phone Number
  const [length, setLength] = useState('');
  const [breadth, setBreadth] = useState('');
  const [product, setProduct] = useState('');
  const [unit, setUnit] = useState('MM');
  const [originalLength, setOriginalLength] = useState('');
  const [originalBreadth, setOriginalBreadth] = useState('');
  const [convertedBaffleSpacing, setConvertedBaffleSpacing] = useState('');
  const [orientation, setOrientation] = useState('');
  const [result, setResult] = useState(null);
  const [selectedProductDimensions, setSelectedProductDimensions] = useState({});
  const [wastage, setWastage] = useState({ totalWastage: 0 });
  const [baffleSpacing, setBaffleSpacing] = useState('');
  const [panelCount, setPanelCount] = useState(0);
  // const [panelCountForPrice, setPanelCountForPrice] = useState(0);
  const [selectedShade, setSelectedShade] = useState(''); // State for selected shade
  const [selectedCategory, setSelectedCategory] = useState(''); //State for selected category in Baffle
  // const [selectedBottomShade, setSelectedBottomShade] = useState('');
  const [estimatedPricePanels, setEstimatedPricePanels] = useState(0); // State for estimated price
  const [estimatedPriceJtrims, setEstimatedPriceJtrims] = useState(0);
  const [estimatedPriceTtrims, setEstimatedPriceTtrims] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [generatePDF, setGeneratePDF] = useState(false);
  const [dimensionInputResult, setDimensionInputResult] = useState(null); // Store result for dimension inputs
  const [wallResults, setWallResults] = useState([]); // Store results for each wall added
  const [walls, setWalls] = useState([]);
 

 // Callback function to receive walls data from WallInput component
 const handleWallsChange = (newWalls) => {
  setWalls(newWalls);
};

const handleProductChange = (e) => {
  const selectedProduct = e.target.value;
  setProduct(selectedProduct);
  setSelectedShade(null);
  if (selectedProduct) {
    setSelectedProductDimensions(productsConfigInMM[selectedProduct]);
  } else {
    setSelectedProductDimensions({});
    setResult(null);
  }

   // Clear selected category if product is changed
   if (selectedProduct !== 'Baffle') {
    setSelectedCategory('');
  }
};


const handleCalculate = () => {
  if (!name || !city || !state || !phoneNumber || !product || !orientation || !selectedShade ) {
    setShowErrorMessage(true);
    return false;
  } else {
    setShowErrorMessage(false);
  }

  // Check if any wall inputs are incomplete
  const incompleteWallIndex = walls.findIndex(wall => 
    !wall.length || !wall.breadth || !wall.product || !wall.orientation || !wall.shade
  );

  if (incompleteWallIndex !== -1) {
    setShowErrorMessage(true);
    return false;
  }

  // All fields are valid, proceed with calculations
  setShowErrorMessage(false);

  let initialResult = null;
  let wallResults = [];
  let totalPanelCount = 0;
  let totalJTrims = 0;
  let totalTTrims = 0;
  let totalEstimatedCost = 0;
  let totalBaffleCaps = 0;
  

  // Calculate for Dimension Input
  if (product === "Baffle") {
    initialResult = calculateForWall(length, breadth, orientation, product, selectedShade, originalLength, originalBreadth, baffleSpacing);
    totalPanelCount += initialResult.panelCount;
    totalBaffleCaps += initialResult.BaffleCapCount
    totalEstimatedCost += initialResult.estimatedPrice + initialResult.baffleCapPriceCalculation + initialResult.bottomShadeEstimatedPrice;
  }
  else if(product){
    initialResult = calculateForWall(length, breadth, orientation, product, selectedShade, originalLength, originalBreadth, baffleSpacing);
    totalPanelCount += initialResult.panelCount;
    totalJTrims += initialResult.jTrims;
    totalTTrims += initialResult.tTrims;
    totalEstimatedCost += initialResult.estimatedPrice + initialResult.estimatedPriceJtrims + initialResult.estimatedPriceTtrims;
  }

  // Calculate for each wall
  walls.forEach((wall) => {
    if (wall.product === 'Baffle' && wall.baffleSpacing) {
      const wallResult = calculateForWall(
        wall.convertedLength,
        wall.convertedBreadth,
        wall.orientation,
        wall.product,
        wall.shade, // Pass wall-specific shade
        wall.length,
        wall.breadth,
        wall.baffleSpacing,
      );
      wallResults.push(wallResult);
      totalPanelCount += wallResult.panelCount;
      totalBaffleCaps += wallResult.BaffleCapCount
      totalEstimatedCost += wallResult.estimatedPrice + wallResult.baffleCapPriceCalculation + wallResult.bottomShadeEstimatedPrice;
    } else if (wall.product && wall.shade) {
      const wallResult = calculateForWall(
        wall.convertedLength,
        wall.convertedBreadth,
        wall.orientation,
        wall.product,
        wall.shade, // Pass wall-specific shade
        wall.length,
        wall.breadth
      );
      wallResults.push(wallResult);
      totalPanelCount += wallResult.panelCount;
      totalJTrims += wallResult.jTrims;
      totalTTrims += wallResult.tTrims;
      totalEstimatedCost += wallResult.estimatedPrice + wallResult.estimatedPriceJtrims + wallResult.estimatedPriceTtrims;
    }
  });
 

  setResult({ initialResult, wallResults, totalPanelCount, totalJTrims, totalTTrims, totalEstimatedCost, totalBaffleCaps});
  return true;
};


  const calculateForWall = (length, breadth, orientation, product, shade,  originalLength, originalBreadth, baffleSpacing) =>{

    let BaffleCapCount = 0;
    // let estimatedPrice = 0;
    // let estimatedPriceJtrims = 0;
    // let estimatedPriceTtrims = 0;

    let panelLength, panelBreadth;

    if (product === 'Soffit' || product === 'Duo') {
      panelLength = productsConfig[product].panelLengthFt;
      panelBreadth = productsConfig[product].panelBreadth;
    } else {
      panelLength = productsConfigInMM[product].panelLength;
      panelBreadth = productsConfigInMM[product].panelBreadth;
    }

    let panelCount = 0;
    let jTrims = 0;
    let tTrims = 0;

    // Convert length and breadth from mm to feet if needed 
    const lengthInFeet = mmToFeet(parseFloat(length));
    const breadthInFeet = mmToFeet(parseFloat(breadth));

    //Convert Linea length and breadth from mm to feet
    const LinealengthInFeet = lineammToFeet(parseFloat(length));
    const LineabreadthInFeet = lineammToFeet(parseFloat(breadth));


    const calculateSoffitAndDuoPanels = (lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation) => {     
      if (orientation === 'Horizontal') {
        // if(unit === 'mm'){
          return lineaHorizontalPanelCount(lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation);
        // }else if(unit === 'feet'){
        //   return lineaHorizontalPanelCount(lengthInFeet, breadthInFeet, panelLength, panelBreadth);
        // }
      } else if (orientation === 'Vertical') {
        // if(unit === 'mm'){
          return lineaVerticalPanelCount(breadthInFeet, lengthInFeet, panelLength, panelBreadth);
        // }else if(unit === 'feet'){
        //   return lineaVerticalPanelCount(breadthInFeet, lengthInFeet, panelLength, panelBreadth);
        // }
        }
    };


    const calculateLineaPanels = (lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation) => {
      if (orientation === 'Horizontal') {
        // if(unit === 'mm'){
          return horizontalPanelCountInMM(lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation);
          
        // }else if(unit === 'feet'){
        //   return lineaHorizontalPanelCount(lengthInFeet, breadthInFeet, panelLength, panelBreadth);
        // }
      } else if (orientation === 'Vertical') {
        // if(unit === 'mm'){
          return VerticalPanelCountInMM(breadthInFeet, lengthInFeet, panelLength, panelBreadth);
        // }else if(unit === 'feet'){
        //   return lineaVerticalPanelCount(breadthInFeet, lengthInFeet, panelLength, panelBreadth);
        // }
      }
    };

    const calculateSOffitLargerPanels = (lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation) => {
      if (orientation === 'Horizontal') {
        // if(unit === 'mm'){
          return horizontalSoffitLargerPanelCountInMM(lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation);
          
        // }else if(unit === 'feet'){
        //   return lineaHorizontalPanelCount(lengthInFeet, breadth InFeet, panelLength, panelBreadth);
        // }
      } else if (orientation === 'Vertical') {
        // if(unit === 'mm'){
          return VerticalSoffitLargerPanelCountInMM(breadthInFeet, lengthInFeet, panelLength, panelBreadth);
        // }else if(unit === 'feet'){
        //   return lineaVerticalPanelCount(breadthInFeet, lengthInFeet, panelLength, panelBreadth);
        // }
      }
    };

    const calculateDuraPanels = (length, breadth, panelLength, panelBreadth, orientation) => {
      if (orientation === 'Horizontal') {
        return calculateDuraTotalItemsHorizontal(length, breadth, panelLength, panelBreadth);
      } else if (orientation === 'Vertical') { 
        return calculateDuraTotalItemsVertical(breadth, length, panelLength, panelBreadth);  
      }   
    };

    const calculateStretaPanels = (lengthInFeet, breadthInFeet, panelLength, panelBreadth, orientation) => {
      if (orientation === 'Horizontal') {
          return calculateStretaHorizontalPanelCount(lengthInFeet, breadthInFeet, panelLength, panelBreadth);
        
      } else if (orientation === 'Vertical') {
         return calculateStretaVerticalPanelCount(breadthInFeet, lengthInFeet, panelLength, panelBreadth);
      };
    }
    const calculateBafflePanels = (length, breadth, panelLength, baffleSpacing, orientation) => {
      if(orientation === 'Horizontal'){
        return BafflehorizontalPanelCountInMM(length, breadth, panelLength, baffleSpacing, orientation, panelBreadth,);
      }else if(orientation === 'Vertical'){
        return BaffleverticalPanelCountInMM(length, breadth, panelLength, baffleSpacing, orientation , panelBreadth)
      }
    }

    // Calculate panels based on product and orientation
    if(product === 'Soffit' || product === 'Duo'){
      panelCount = calculateSoffitAndDuoPanels(length, breadth, panelLength, panelBreadth, orientation);  
    }
    else if(product === 'Soffit2' || product === 'Duo2' || product === 'Louvers2'){
      panelCount = calculateSOffitLargerPanels(length, breadth, panelLength, panelBreadth, orientation);
    }
    else if(product === 'Norma'){
      panelCount = calculateDuraPanels(length, breadth, panelLength, panelBreadth, orientation);
    }
    else if(product === 'Streta'){
      panelCount = calculateStretaPanels(length, breadth, panelLength, panelBreadth, orientation );
    }
    else if(product === 'Baffle'){
      panelCount = calculateBafflePanels(length, breadth, panelLength, baffleSpacing, orientation);
      console.log(panelCount)
    }
     else {
      panelCount = calculateLineaPanels(length, breadth, panelLength, panelBreadth, orientation);
      // panelCountForPrice = calculateLineaPanelsForPrice(length, breadth, panelLength, panelBreadthForPrice, orientation );
    }

    
     // if(unit === 'mm'){
      jTrims = product === 'Baffle' ? 0 : (product === 'Norma' || product === 'Streta') ? JtrimsFormulaDura(lengthInFeet,breadthInFeet) 
      : (product === 'Soffit' || product === 'Duo') ? JtrimsFormula(length, breadth) : JtrimsFormula(lengthInFeet, breadthInFeet);
    
     
    let totalWastage = 0;

    if (orientation === 'Horizontal') {
      if(product === 'Soffit2' || product === 'Duo2' || product === 'Louvers2'){
        tTrims =  lengthInFeet > 13 ? TtrimsFormula(LinealengthInFeet, LineabreadthInFeet, TtrimsLength) : 0;
        totalWastage = HorizontalWastageOfPanel(LinealengthInFeet, LineabreadthInFeet, panelLength);
      }else if(product === 'Soffit' || product === 'Duo'){
        tTrims = length > 10 ? TtrimsFormula(length, breadth, TtrimsLength) : 0;
        totalWastage = HorizontalWastageOfPanel(LinealengthInFeet, LineabreadthInFeet, panelLength);
      }else{
        tTrims = product === 'Baffle' ? 0 : lengthInFeet > 10 ? (product === 'Norma' || product === 'Streta') ? TtrimsFormulaDura(LinealengthInFeet, LineabreadthInFeet, TtrimsLengthDura) : 
        TtrimsFormula(LinealengthInFeet, LineabreadthInFeet, TtrimsLength) : 0;
        totalWastage = HorizontalWastageOfPanel(LinealengthInFeet, LineabreadthInFeet, panelLength);
      }
      
     
    } else if (orientation === 'Vertical') {
      if(product === 'Soffit2' || product === 'Duo2' || product === 'Louvers2'){
        tTrims =  breadthInFeet > 13 ? TtrimsVerticalFormula(LinealengthInFeet, LineabreadthInFeet, TtrimsLength) : 0;
        totalWastage = HorizontalWastageOfPanel(LinealengthInFeet, LineabreadthInFeet, panelLength);
      }else if(product === 'Soffit' || product === 'Duo'){
        tTrims = breadth > 10 ? TtrimsVerticalFormula(length, breadth, TtrimsLength) : 0;
        totalWastage = VerticalWastageOfPanel(LinealengthInFeet, LineabreadthInFeet, panelLength);
      }else{
        tTrims = product === 'Baffle' ? 0 : breadthInFeet > 10 ? (product === 'Norma' || product === 'Streta') ? TtrimsVerticalFormulaDura(LinealengthInFeet, LineabreadthInFeet, TtrimsLengthDura) :
        TtrimsVerticalFormula(LinealengthInFeet, LineabreadthInFeet, TtrimsLength) : 0;
        totalWastage = VerticalWastageOfPanel(LinealengthInFeet, LineabreadthInFeet, panelLength);
      }
    }

      if(orientation === 'Horizontal'){
        BaffleCapCount = BaffleCapHorizontal(breadth, baffleSpacing);
      }else if(orientation === 'Vertical'){
        BaffleCapCount = BaffleCapVertical(length, baffleSpacing);
      }

      const perPcSqFt = productsConfigInMM[product].perPcSqFt;
      const totalSqFt = panelCount * perPcSqFt;
      const estimatedPrice = totalSqFt * shade.price;
      // setEstimatedPricePanels(estimatedPrice);
    
    
      const JTrimperPcSqFt = productsConfigInMM[product].JTrimperPcSqFt;
      const JTrimtotalSqFt = jTrims * JTrimperPcSqFt;
      const estimatedPriceJtrims = JTrimtotalSqFt * shade.jTrimPrice;
      // setEstimatedPriceJtrims(estimatedPriceJtrims);

      const TTrimperPcSqFt = productsConfigInMM[product].TTrimperPcSqFt;
      const TTrimtotalSqFt = tTrims * TTrimperPcSqFt;
      const estimatedPriceTtrims = TTrimtotalSqFt * shade.TtrimPrice;
      // setEstimatedPriceTtrims(estimatedPriceTtrims);

      const bottomShadePerPcSqFt = 50;
      const bottomShadeEstimatedPrice = totalSqFt * bottomShadePerPcSqFt;

      const baffleCapPriceCalculation = BaffleCapCount * shade.finishingCapPrice;

      return {
        panelCount: Math.ceil(panelCount),
        jTrims,
        tTrims,
        BaffleCapCount,
        estimatedPrice,
        estimatedPriceJtrims,
        estimatedPriceTtrims,
        bottomShadeEstimatedPrice,
        baffleCapPriceCalculation,
        originalLength,  // Store original length
        originalBreadth, // Store original breadth
        length, // Add length here
        breadth, // Add breadth here
        orientation, // Add orientation here
        product, // Add product here
        selectedShade: shade // Add selectedShade here
      }
    };
  

      // const perPcSqFt = productsConfigInMM[product].perPcSqFt;
      // const totalSqFt = totalPanelCount * perPcSqFt;  
      // const estimatedPrice = totalSqFt * selectedShade.price;
      // setEstimatedPricePanels(estimatedPrice);

      // const JTrimperPcSqFt = productsConfigInMM[product].JTrimperPcSqFt;
      // const JTrimtotalSqFt = totalJTrims * JTrimperPcSqFt;
      // const estimatedPriceJtrims = JTrimtotalSqFt * selectedShade.jTrimPrice;
      // setEstimatedPriceJtrims(estimatedPriceJtrims);

      // const TTrimperPcSqFt = productsConfigInMM[product].TTrimperPcSqFt;
      // const TTrimtotalSqFt = totalTTrims * TTrimperPcSqFt;
      // const estimatedPriceTtrims = TTrimtotalSqFt * selectedShade.TtrimPrice;
      // setEstimatedPriceTtrims(estimatedPriceTtrims);

      // const perPcSqFt = productsConfigInMM[product].perPcSqFt;
      // const totalSqFt = panelCount * perPcSqFt;
      // const estimatedPrice = totalSqFt * selectedShade.price;
      // setEstimatedPricePanels(estimatedPrice);
    
    
      // const JTrimperPcSqFt = productsConfigInMM[product].JTrimperPcSqFt;
      // const JTrimtotalSqFt = jTrims * JTrimperPcSqFt;
      // const estimatedPriceJtrims = JTrimtotalSqFt * selectedShade.jTrimPrice;
      // setEstimatedPriceJtrims(estimatedPriceJtrims);

      // const TTrimperPcSqFt = productsConfigInMM[product].TTrimperPcSqFt;
      // const TTrimtotalSqFt = tTrims * TTrimperPcSqFt;
      // const estimatedPriceTtrims = TTrimtotalSqFt * selectedShade.TtrimPrice;
      // setEstimatedPriceTtrims(estimatedPriceTtrims);

      // const bottomShadePerPcSqFt = 50;
      // const bottomShadeEstimatedPrice = totalSqFt * bottomShadePerPcSqFt;

      // const baffleCapPriceCalculation = BaffleCapCount * selectedShade.finishingCapPrice;

  // Set total result
  // setResult({
  //   panelCount: totalPanelCount,
  //   jTrims: totalJTrims,
  //   tTrims: totalTTrims,
  //   estimatedPricePanels: estimatedPricePanels,
  //   estimatedPriceJtrims: estimatedPriceJtrims,
  //   estimatedPriceTtrims: estimatedPriceTtrims,
  //   BaffleCapCount: BaffleCapCount
  // });

  // Trigger PDF generation after calculation
  // setGeneratePDF(true);
// };

// console.log("Outside PDF function - Panel Count:", result.panelCount); // Logs correct value
// console.log("Outside PDF function - Full Result Object:", result); // Logs the full result object


// Spacing adjustments for cleaner layout

const handleExportToPDF = () => {
  const estimatedPricePanels = result?.initialResult?.estimatedPrice || 0;
  const estimatedPriceJtrims = result?.initialResult?.estimatedPriceJtrims || 0;
  const estimatedPriceTtrims = result?.initialResult?.estimatedPriceTtrims || 0;
  const totalBaffleCapPrice = result?.initialResult?.baffleCapPriceCalculation || 0;
  const bottomShadeEstimatedPrice = result?.initialResult?.bottomShadeEstimatedPrice || 0;


  const doc = new jsPDF();
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Set a maximum and minimum width for the logo to handle different screen sizes
  const maxLogoWidth = 50; // Maximum width for the logo
  const minLogoWidth = 25; // Minimum width for the logo

  // Calculate the logo width based on the page width (responsive)
  let logoWidth = pageWidth * 0.1; // 10% of the page width
  logoWidth = Math.max(minLogoWidth, Math.min(maxLogoWidth, logoWidth)); // Ensure it's between the max and min values

  const logoHeight = logoWidth; // Maintain aspect ratio by setting height equal to width
  const centerX = (pageWidth - logoWidth) / 2; // Calculate the x position for center alignment

  // Centered top logo
  doc.addImage(companyLogo, 'PNG', centerX, 0, logoWidth, logoHeight); // Adjusted Y position to avoid being too close to the top edge


  // Main Heading ("Quotation")
  doc.setFont('helvetica', 'bold');
  doc.setFontSize(24);
  doc.setTextColor(81, 81, 81);
  doc.text('QUOTATION', pageWidth / 2, 35, { align: 'center' });

  // Add Customer Details
  const customerDetails = [
    [{ content: 'NAME', styles: { fontStyle: 'bold' } }, name, { content: 'PHONE NUMBER', styles: { fontStyle: 'bold' } }, phoneNumber],
    [{ content: 'CITY', styles: { fontStyle: 'bold' } }, city, { content: 'STATE', styles: { fontStyle: 'bold' } }, state],
  ];

  doc.autoTable({
    startY: 44,
    body: customerDetails,
    theme: 'grid',
    styles: { 
      fillColor: [255, 255, 255],
      textColor: [81, 81, 81],
      fontStyle: 'normal',
      fontSize: 10, 
    },
    columnStyles: {
      0: { cellWidth: 30, halign: 'left' },  // Adjust column widths
      1: { cellWidth: 50, halign: 'left' },
      2: { cellWidth: 50, halign: 'left' },
      3: { cellWidth: 50, halign: 'left' },
    },
    tableWidth: 'wrap',
  });

  let finalY = doc.previousAutoTable.finalY;

    const dimensionOrientationTable = [
      [`WIDTH (${unit})`, originalLength],
      [`LENGTH (${unit})`, originalBreadth],
      ['ORIENTATION', orientation.toUpperCase()],
    ];

    const imgWidth = 100; // Width of the orientation image
    const imgHeight = 70; // Height of the orientation image
    const margin = 10; // Margin between text and image
    let startYHeading = finalY + 20;

     // Draw the red line to the left of the "Orientation" heading
     doc.setDrawColor(197, 22, 28); // Set the color to red
     doc.setLineWidth(1.5); // Set the width of the line
     doc.line(12, startYHeading - 1, 12, startYHeading + 4); // Draw the vertical line closer to the text
 

    // Add Dimension Input Results (like in your UI)
  if (result?.initialResult) {
    // Add the Orientation Table for Dimension Input
    doc.setFontSize(14);
    doc.setTextColor(81, 81, 81); // Set the text color for the heading
    doc.text('Orientation Wall 1', 15, startYHeading + 3);

    doc.autoTable({
      startY: startYHeading + 10,
      head: [['PROPERTY', 'VALUE']],
      body: dimensionOrientationTable,
      theme: 'grid',
      styles: {  fillColor: [255, 255, 255] },
      headStyles: { fillColor: [114, 114, 114], textColor: [255, 255, 255] }, // Dark slate blue background with white text
      tableWidth: 'wrap',
      margin: { right: imgWidth + margin }, // Leave space for the orientation image
    });

    finalY  = doc.previousAutoTable.finalY;

    // Add the orientation image next to the Orientation Table
    const orientationImage = orientation.toLowerCase() === 'horizontal' ? horizontalImage : verticalImage;
    doc.addImage(orientationImage, 'PNG', pageWidth - imgWidth - margin, startYHeading + 2 - 7, imgWidth, imgHeight); // Adjust placement and size

    // Set the Y position for the "Requirements" heading
    let startYRequirements = finalY  + 30;
    const startYRequirementsHeading = startYRequirements - 7;

    // Draw the red line to the left of the "Requirements" heading
    doc.setDrawColor(197, 22, 28); // Set the color to red
    doc.setLineWidth(1.5); // Set the width of the line
    doc.line(12, startYRequirementsHeading - 1, 12, startYRequirementsHeading + 4); // Draw the vertical line closer to the text

    // Add the Requirement Table for Dimension Input
    doc.setFontSize(14);
    doc.setTextColor(81, 81, 81); // Set the text color for the heading
    doc.text('Requirements Wall 1', 15, startYRequirementsHeading + 3);
    
     // Add the small paragraph below "Requirements"
     doc.setFontSize(10);
     doc.setFont('helvetica', 'normal'); // Set the font back to normal
     doc.text('Please note the quotation for your requirements shared', 15, startYRequirements + 3); // Add the note below the "Requirements" heading

    var totalEstimatedCost = 0;

    const dimensionRequirementsTable = generateTableForResult(
      result.initialResult,
      product,
      productsConfigInMM,
      selectedShade,
      originalLength,
      originalBreadth,
      orientation
    );

    doc.autoTable({
      startY: startYRequirements + 12,
      head: dimensionRequirementsTable.head,
      body: dimensionRequirementsTable.body,
      theme: 'striped',
      styles: {
        fillColor: [255, 255, 255],
        lineColor: [114, 114, 114], // Set border color for the cells
        lineWidth: 0.3 // Set border width for the cells
    },
      headStyles: {
        fillColor: [114, 114, 114], 
        textColor: [255, 255, 255],
        lineColor: [255, 255, 255], // Border color for header cells
        lineWidth: 0.3 // Border width for header cells
      },
      columnStyles: {
        0: { cellWidth: 'auto', halign: 'center' }, // SR. NO column
        1: { cellWidth: 'auto', halign: 'left' },   // PRODUCT column
        2: { cellWidth: 'auto', halign: 'left' },   // SHADE column
        3: { cellWidth: 'auto', halign: 'center' }, // QUANTITY column
        4: { cellWidth: 'auto', halign: 'center' }, // SIZE column
        5: { cellWidth: 'auto', halign: 'center' }, // MRP column
        6: { cellWidth: 'auto', halign: 'right' },  // TOTAL ESTIMATION column
      },
    });

    finalY  = doc.previousAutoTable.finalY + 15; // Adjusting the space below the table
    totalEstimatedCost = estimatedPricePanels 
                            + (product !== 'Baffle' ? estimatedPriceJtrims : 0) + (product === 'Baffle' ? bottomShadeEstimatedPrice : 0)
                            + (product !== 'Baffle' && result.initialResult.tTrims > 0 ? estimatedPriceTtrims : 0) + (product === 'Baffle' ? totalBaffleCapPrice : 0);

    // doc.setFont('helvetica', 'bold');
    // doc.setFontSize(10);
    // doc.text('Installation Cost:              As Actuals', 130, afterTableY);
    // doc.setFont('helvetica', 'bold');
    // doc.setFontSize(10);
    // doc.text(`TOTAL ESTIMATED COST (INCL. GST):          Rs.${totalEstimatedCost.toFixed(2)}`, 95, afterTableY + 7);

  }

  // Add Wall Input Results if any walls are added
  if (result?.wallResults?.length > 0) {
    result.wallResults.forEach((wallResult, index) => {

    const startYHeading = finalY + 20;

    // Check if space is available, if not, add a new page
    if (startYHeading + 35 > pageHeight) {
      doc.addPage();
      finalY = 10;
    }

    // Orientation Table for Wall Input
      let wallOrientationTable = [
        [`WIDTH (${unit})`, wallResult.originalLength],
        [`LENGTH (${unit})`, wallResult.originalBreadth],
        ['ORIENTATION', wallResult.orientation.toUpperCase()],
      ];


      // Draw the red line to the left of the "Orientation" heading
      doc.setDrawColor(197, 22, 28); // Set the color to red
      doc.setLineWidth(1.5); // Set the width of the line
      doc.line(12, finalY - 1, 12, finalY + 4); // Draw the vertical line closer to the text

      // Add the "Orientation" heading For Wall
      doc.setFontSize(14);
      doc.setTextColor(81, 81, 81); // Set the text color for the heading
      doc.text(`Orientation Wall ${index + 2}`, 15, finalY + 3);

      doc.autoTable({
        startY: finalY + 10,
        head: [['PROPERTY', 'VALUE']],
        body: wallOrientationTable,
        theme: 'grid',
        styles: { fillColor: [255, 255, 255] },
        headStyles: { fillColor: [114, 114, 114], textColor: [255, 255, 255] }, // Dark slate blue background with white text
        tableWidth: 'wrap',
        margin: { right: imgWidth + margin }, // Leave space for the orientation image
      });

      finalY = doc.previousAutoTable.finalY;

      // Add the orientation image next to the Orientation Table for Wall Input
      const wallOrientationImage = wallResult.orientation.toLowerCase() === 'horizontal' ? horizontalImage : verticalImage;
      doc.addImage(wallOrientationImage, 'PNG', pageWidth - imgWidth - margin, finalY + 2 - 47, imgWidth, imgHeight); // Adjust placement and size
      
      finalY += 30;

      const startYRequirementsHeading = finalY - 7;
      if (startYRequirementsHeading + 50 > pageHeight) {
        doc.addPage();
        finalY = 10; // Reset finalY for the new page
    }

    const startYRequirementsForWalls = finalY + 3;

      // Draw the red line to the left of the "Requirements" heading
      doc.setDrawColor(197, 22, 28); // Set the color to red
      doc.setLineWidth(1.5); // Set the width of the line 
      doc.line(12, startYRequirementsForWalls, 12, startYRequirementsForWalls + 5); // Draw the vertical line closer to the text

      // Requirement Table for Wall Input
      doc.setFontSize(14);
      doc.setFont('helvetica', 'normal');
      doc.text(`Requirements Wall ${index + 2}`, 15, finalY + 7);

      // Add the small paragraph below "Requirements"
      doc.setFontSize(10);
      doc.setFont('helvetica', 'normal'); // Set the font back to normal
      doc.text('Please note the quotation for your requirements shared', 15, finalY + 12); // Add the note below the "Requirements" heading

      const wallRequirementsTable = generateTableForResult(
        wallResult,
        wallResult.product,
        productsConfigInMM,
        wallResult.selectedShade,
        wallResult.originalLength,
        wallResult.originalBreadth,
        wallResult.orientation
      );

      doc.autoTable({
        startY: startYRequirementsForWalls + 13,
        head: wallRequirementsTable.head,
        body: wallRequirementsTable.body,
        styles: {
          fillColor: [255, 255, 255],
          lineColor: [114, 114, 114], // Set border color for the cells
          lineWidth: 0.3 // Set border width for the cells
      },
      headStyles: {
        fillColor: [114, 114, 114], 
        textColor: [255, 255, 255],
        lineColor: [255, 255, 255], // Border color for header cells
        lineWidth: 0.3 // Border width for header cells
      },
      columnStyles: {
        0: { cellWidth: 'auto', halign: 'center' }, // SR. NO column
        1: { cellWidth: 'auto', halign: 'left' },   // PRODUCT column
        2: { cellWidth: 'auto', halign: 'left' },   // SHADE column
        3: { cellWidth: 'auto', halign: 'center' }, // QUANTITY column
        4: { cellWidth: 'auto', halign: 'center' }, // SIZE column
        5: { cellWidth: 'auto', halign: 'center' }, // MRP column
        6: { cellWidth: 'auto', halign: 'right' },  // TOTAL ESTIMATION column
      },
    });

    finalY = doc.previousAutoTable.finalY + 20; // Adjusting the space below the table
    // Calculate the total estimated cost for the current wall
    const wallEstimatedCost = wallResult.estimatedPrice
      + (wallResult.product !== 'Baffle' ? wallResult.estimatedPriceJtrims : 0)
      + (wallResult.product === 'Baffle' ? wallResult.bottomShadeEstimatedPrice : 0)
      + (wallResult.product !== 'Baffle' && wallResult.tTrims > 0 ? wallResult.estimatedPriceTtrims : 0)
      + (wallResult.product === 'Baffle' ? wallResult.baffleCapPriceCalculation : 0);

  //   // Display the total estimated cost for the wall
  //   doc.setFont('helvetica', 'bold');
  //   doc.setFontSize(10);
  //   doc.text('Installation Cost:              As Actuals', 130, afterTableY);
  //   doc.setFont('helvetica', 'bold');
  //   doc.setFontSize(10);
  //   doc.text(`TOTAL ESTIMATED COST (INCL. GST):          Rs.${wallEstimatedCost.toFixed(2)}`, 95, afterTableY + 7);
  });
}

    // Check if there's enough space on the page for Total Summary and Terms & Conditions
    if (finalY + 10 > pageHeight) {
      doc.addPage(); // Add a new page if space is insufficient
      finalY = 10; // Reset Y position for new page
    }

   // Add the Total Summary table just above Terms & Conditions
   const totalSummaryStartY = doc.previousAutoTable.finalY + 10;

   // Draw the red line to the left of the "Total Summary" heading
   doc.setDrawColor(197, 22, 28); // Set the color to red
   doc.setLineWidth(1.5); // Set the width of the line
   doc.line(12, totalSummaryStartY - 1, 12, totalSummaryStartY + 4); // Draw the vertical line closer to the text
 
   doc.setFontSize(14);
   doc.text('Total Summary', 15, totalSummaryStartY + 3);

   const totalSummaryTable = [];

// Add the total for Wall 1 (Initial Result)
totalSummaryTable.push(
  ['Wall 1 Total', `Rs. ${totalEstimatedCost.toFixed(2)}`]
);

// Add totals for each additional wall
result.wallResults.forEach((wallResult, index) => {
  const wallEstimatedCost = wallResult.estimatedPrice
    + (wallResult.product !== 'Baffle' ? wallResult.estimatedPriceJtrims : 0)
    + (wallResult.product === 'Baffle' ? wallResult.bottomShadeEstimatedPrice : 0)
    + (wallResult.product !== 'Baffle' && wallResult.tTrims > 0 ? wallResult.estimatedPriceTtrims : 0)
    + (wallResult.product === 'Baffle' ? wallResult.baffleCapPriceCalculation : 0);

  totalSummaryTable.push(
    [`Wall ${index + 2} Total`, `Rs. ${wallEstimatedCost.toFixed(2)}`]
  );
});

totalSummaryTable.push(
  ['Total Estimated Cost of All Walls (Incl. GST)', `Rs. ${result.totalEstimatedCost.toFixed(2)}`]
);

 
  //  const totalSummaryTable = [
  //    ['Total Panel Quantity Incl. All Walls', `${result.totalPanelCount} Panels`],
  //    [`Total Trims ${result.totalBaffleCaps > 0 ? "& Baffle Caps " : ''}Count Incl. All Walls`, `${result.totalBaffleCaps > 0 ? result.totalBaffleCaps + ' Baffle Caps, ' : ''}${result.totalJTrims > 0 ? '' + result.totalJTrims + ' J-Trims' : ''}${result.totalTTrims > 0 ? ', ' + result.totalTTrims + ' T-Trims' : ''}`],
  //    ['Total Estimated Cost of All Walls (Incl. GST)', `Rs. ${result.totalEstimatedCost.toFixed(2)}`],
  //  ];
 
   doc.autoTable({
     startY: totalSummaryStartY + 8,
     head: [['DESCRIPTION', 'VALUE']],
     body: totalSummaryTable,
     theme: 'grid',
     styles: {
       fillColor: [255, 255, 255],
       textColor: [81, 81, 81],
     },
     headStyles: {
       fillColor: [114, 114, 114],
       textColor: [255, 255, 255],
     },
     tableWidth: 'wrap',
   });
 
   finalY = doc.previousAutoTable.finalY + 10;

   // Check again if there’s enough space for Terms & Conditions
   if (finalY + 50 > pageHeight) {
     doc.addPage();
     finalY = 10;
   }
 
   // Add Terms & Conditions
  const termsStartY = finalY + 20;
  doc.setFillColor(245, 245, 245);
  doc.rect(10, termsStartY - 7, pageWidth - 20, 60, 'F');

  doc.setFont('helvetica', 'bold');
  doc.setFontSize(14);
  doc.setTextColor(81, 81, 81);
  doc.text('TERMS & CONDITIONS', 15, termsStartY + 2);

  const terms = [
    { number: '1. ', text: 'This quotation does not include installation, transportation, or any other costs involved. It only accounts for PARÉ Materials required.' },
    { number: '2. ', text: 'The quantities mentioned are strictly indicative, please cross-check the sizing with your contractor once before placing an order.' },
    { number: '3. ', text: 'The rates mentioned are MRP only. This quotation does not account for any special pricing/discount, if applicable.' }
  ];

  let currentY = termsStartY + 10;
  terms.forEach(item => {
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(item.number, 15, currentY); 
    doc.setFont('helvetica', 'normal');
    const splitText = doc.splitTextToSize(item.text, pageWidth - 30);
    doc.text(splitText, 20, currentY);
    currentY += splitText.length * doc.getTextDimensions(splitText).h + 1.5;
  });

  doc.save('Quotation.pdf');
};


//Helper function to generate the table for each wall or dimension result
const generateTableForResult = (result, product, productsConfigInMM, selectedShade, length, breadth, orientation) => {
  const isBaffle = product === 'Baffle';
  let jTrimsName = 'J-Trims Large';
  let tTrimsName = 'T-Trims Large';

  if(product=== 'Soffit' || product=== 'Soffit2' || product === 'Duo' ||  product=== 'Duo2'|| product === 'Louvers' || product === 'Louvers2' || product === 'Norma' || product === 'Streta'){
    jTrimsName = 'J-Trims'
    tTrimsName = 'T-Trims'
  }


  const tableHead = [['Sr. No', 'Product', 'Shade', 'Required Quantity', 'Per Pc / Sq Ft', 'MRP / Sq Ft', 'Total Estimation']];
  const tableBody = [];

  // Add Panel data
  tableBody.push([
    '1',
    productsConfigInMM[product]?.name || 'N/A',
    selectedShade?.name || 'N/A',
    result.panelCount ? `${result.panelCount} Panels` : 'N/A',
    productsConfigInMM[product]?.perPcSqFt || 'N/A',
    selectedShade?.price || 'N/A',
    `Rs.${result.estimatedPrice.toFixed(2)}`,
  ]);

  // Add additional data for Baffle (if applicable)
  if (isBaffle) {
    tableBody.push([
      '2',
      'Baffle Bottom',
      'Black',
      `${result.panelCount} Panels`,
      productsConfigInMM[product]?.perPcSqFt || 'N/A',
      '50',
      `Rs.${result.bottomShadeEstimatedPrice.toFixed(2)}`,
    ]);

    tableBody.push([
      '3',
      'Baffle Finishing Caps',
      'Black',
      `${result.BaffleCapCount} Baffle Caps`,
      '-',
      selectedShade?.finishingCapPrice || 'N/A',
      `Rs.${result.baffleCapPriceCalculation.toFixed(2)}`,
    ]);
  }

  // Add J-Trims and T-Trims (if applicable)
  if (!isBaffle && result.jTrims) {
    tableBody.push([
      '2',
      `${jTrimsName}`,
      selectedShade?.name || 'N/A',
      `${result.jTrims} J-Trims`,
      productsConfigInMM[product]?.JTrimperPcSqFt || 'N/A',
      selectedShade?.jTrimPrice || 'N/A',
      `Rs.${result.estimatedPriceJtrims.toFixed(2)}`,
    ]);
  }

  if (!isBaffle && result.tTrims > 0) {
    tableBody.push([
      '3',
      `${tTrimsName}`,
      selectedShade?.name || 'N/A',
      `${result.tTrims} T-Trims`,
      productsConfigInMM[product]?.TTrimperPcSqFt || 'N/A',
      selectedShade?.TtrimPrice || 'N/A',
      `Rs.${result.estimatedPriceTtrims.toFixed(2)}`,
    ]);
  }
  return { head: tableHead, body: tableBody };
};



const handleGenerateAndExport = async () => {
  // Perform calculations
  const calculationSuccess = handleCalculate();
  
  // If calculation was successful, proceed to generate the PDF
  if (calculationSuccess) {
    setGeneratePDF(true); 
  } else {
    console.error('Calculation failed or result is not ready.');
  }
};

useEffect(() => {
  if (result && generatePDF) {

    handleExportToPDF();
    setGeneratePDF(false);  // Reset the trigger
  }
}, [result, generatePDF]);  // Trigger when either result or generatePDF changes

// useEffect(() => {
//   if (generatePDF) {
//     handleExportToPDF();
//     // sendDataToGoogleSheets();  // Call sendDataToGoogleSheets immediately after PDF generation
//     setGeneratePDF(false);  // Reset the trigger after actions are performed
//   }
// }, [generatePDF, result]); // Ensure this effect runs when `generatePDF` or `result` changes  


// useEffect(() => {
//   if (generatePDF && result) {
//     setTimeout(() => {
//       handleExportToPDF(); // Trigger PDF generation
//       setGeneratePDF(false);
//     }, 100); // Delay by 100ms to ensure `result` is populated
//   }
// }, [generatePDF, result]); // Ensure this effect runs when `generatePDF` or `result` changes    


  // Function to send data to Google Sheets via Google Apps Script Web App
  const sendDataToGoogleSheets = async () => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('phoneNumber', phoneNumber);
    formData.append('product', product);
    formData.append('length', `${originalLength} ${unit}`);
    formData.append('breadth', `${originalBreadth} ${unit}`);
    formData.append('orientation', orientation);
    formData.append('selectedShade', selectedShade ? selectedShade.name : '');
    formData.append('selectedCategory', selectedCategory ? selectedCategory : 'N/A');
    formData.append('baffleSpacing', product === 'Baffle' ? baffleSpacing : 'N/A');

    // Append the calculated quantities
    formData.append('panelCount', result.panelCount); // Number of panels
    formData.append('jTrims', result.jTrims); // Number of J-Trims
    formData.append('tTrims', result.tTrims); // Number of T-Trims
    formData.append('BaffleCapCount', product === 'Baffle' ? result.BaffleCapCount : 'N/A'); // Number of Baffle Caps
  
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbzJlUuNr3__ZWDue4Y8Y_iBA1OzMjw7MvODVTKlwbTevTVO258B1WzECfJZNmRp5g/exec', {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        console.log('Data sent to Google Sheets successfully!');
      } else {
        console.error('Failed to send data to Google Sheets.');
      }
    } catch (error) {
      console.error('Error sending data to Google Sheets:', error);
    }
  };

const handleShadeChange = (shade) => {
  if (!shade) {
    // Handle case when no shade is selected
    setSelectedShade(null);
    setResult(null);
    return;
  }
  const perPcSqFt = productsConfigInMM[product].perPcSqFt;
  const totalSqFt = panelCount * perPcSqFt;
  const estimatedPrice = totalSqFt * shade.price;

  setEstimatedPricePanels(estimatedPrice);
  setSelectedShade(shade);
};
    
    
    // if(product === 'Linea' || product === 'Pyramid'|| product === 'Arch' || product === 'Dome' || product === 'Prism' || product === 'Stripes' || product === 'Unic' ){
    //   console.log("Product Pricing panel Count: " +panelCountForPrice )
    //   const perPcSqFt = productsConfigInMM[product].perPcSqFt;
    //   console.log("Per Sq. Feet Value: " +perPcSqFt)
    //   const totalSqFt = panelCountForPrice * perPcSqFt;
    //   console.log("Total Square. Feet Value: " +totalSqFt)
    //   const estimatedPrice = totalSqFt * shade.price; 
    //   console.log("Estimate Price: "+estimatedPrice)
    //   setEstimatedPricePanels(estimatedPrice);
    //   setSelectedShade(shade);
    // }else{
      // console.log("Product Pricing panel Count: " +panelCount )
      // const perPcSqFt = productsConfigInMM[product].perPcSqFt;
      // // console.log("Per Sq. Feet Value: " +perPcSqFt)
      // const totalSqFt = panelCount * perPcSqFt;
      // // console.log("Total Square. Feet Value: " +totalSqFt)
      // const estimatedPrice = totalSqFt * shade.price;
      // // console.log("Estimate Price: "+estimatedPrice)
      // setEstimatedPricePanels(estimatedPrice);
      // setSelectedShade(shade);
  // }



  // const handleGetQuotation = () => {
  //   // Handle the calculation of the estimated price when "Get Your Quotation" is clicked
  //   const perPcSqFt = productsConfigInMM[product].perPcSqFt;
  //   const totalSqFt = panelCount * perPcSqFt;
  //   const estimatedPrice = totalSqFt * selectedShade.price;
  //   setEstimatedPrice(estimatedPrice);
  // };

   

  const companyLogo = `${process.env.PUBLIC_URL}/assets/logo.png`;
  const horizontalImage = `${process.env.PUBLIC_URL}/assets/horizontal-orientation.png`;
  const verticalImage = `${process.env.PUBLIC_URL}/assets/vertical-orientation.png`;

  // Handle changes from DimensionsInput
  // const handleLengthChange = (newLength) => {
  //   setLength(newLength);
  // };

  // const handleBreadthChange = (newBreadth) => {
  //   setBreadth(newBreadth);
  // };



  return (
    <div className="calculator-container">
      <div className="calculator-heading">
        <h1>PARÉ Quotation Generator</h1>
      </div>  
      <div className="product-calculator-section">
      <div className="product-section-content">
        <div className="customer-details-row">
          <div className="customer-detail">
            <label>Name<span style={{ color: '#BE1E2D' }}>*</span>:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="Full Name"
            />
          </div>
          <div className="customer-detail">
            <label>Phone Number<span style={{ color: '#BE1E2D' }}>*</span>:</label>
            <input
              type="text"
              value={phoneNumber}
              onChange={(e) => {
              setPhoneNumber(e.target.value);
              
              }}
              placeholder="Phone Number"
            />
            
          </div>
          <div className="customer-detail">
              <label>State<span style={{ color: '#BE1E2D' }}>*</span>:</label>
              <select
                value={state}
                onChange={(e) => setState(e.target.value)}
              >
                <option value="">Select State</option>
                {indianStates.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
          <div className="customer-detail">
            <label>City<span style={{ color: '#BE1E2D' }}>*</span>:</label>
            <input
                type="text"
                value={city}
                onChange={(e) => {
                setCity(e.target.value);}}
                placeholder="City"
                />
          </div>
          </div>
          <label>Product<span style={{ color: '#BE1E2D' }}>*</span>:</label>
          <select value={product} onChange={handleProductChange}>
            <option value="">Select Product</option>
            {Object.keys(productsConfig).map((key) => (
              <option key={key} value={key}>
                {productsConfig[key].name}
              </option>
            ))}
          </select>

           {/* Conditionally render BaffleOptions if the product is Baffle */}
          {product === 'Baffle' && (
              <BaffleOptions
                selectedCategory={selectedCategory}
                onSelectCategory={setSelectedCategory}
                selectedShade={selectedShade}
                onSelectShade={setSelectedShade}
                // selectedBottomShade={selectedBottomShade}
                // onSelectBottomShade={setSelectedBottomShade}
              />
            )}

          {product !== 'Baffle' && (
          <ShadesDropdown product={product} selectedShade={selectedShade} onSelectShade={handleShadeChange}/>
          )}
          {/* <FeetInchesToMMConverter/> */}
        </div>
      </div>

      <div className="calculator-section">
        {orientation && (
          <div className='left-side-section-content'>
            {/* <p className='width-text'>Width: {DisplayLengthInFeet}ft.</p> */}
            <div className="orientation-image-container">
            
              {/* <div className="vertical-length-text">
                <p>Length: {DisplayBreadthInFeet} ft.</p>
              </div> */}
            
              <img
                src={orientation === 'Horizontal' ? horizontalImage : verticalImage}
                alt={`${orientation} orientation`}
                style={{ width: '100%', height: 'auto' }}
              />
              
            </div>
            <div className="dynamic-text">
              <p>Width = {originalLength} {unit}.</p>
              <p>Length = {originalBreadth} {unit}.</p>
              <p>Standard Panel Size = {selectedProductDimensions.panelLength}  X  {selectedProductDimensions.panelBreadth} mm</p>
            </div>
          </div>
        )}
        <div className="right-side-section-content">
        <h3>Wall 1</h3>
        {/* <OrientationSwitch
        orientation={orientation}
        setOrientation={setOrientation}
        /> */}

        <div className="orientation-container">
          <label className="orientation-label">Orientation<span style={{ color: '#BE1E2D' }}>*</span>:</label>
          <div className="orientation-radio-group">
            <label className="radio-label">
              <input type="radio" value="Horizontal" checked={orientation === 'Horizontal'} onChange={(e) => setOrientation(e.target.value)} />
              Horizontal
            </label>
            <label className="radio-label">
              <input type="radio" value="Vertical" checked={orientation === 'Vertical'} onChange={(e) => setOrientation(e.target.value)} />
              Vertical
            </label>
          </div>
        </div>
        
        <DimensionsInput
        product={product}
        length={length} 
        breadth={breadth} 
        onLengthChange={setLength}
        onBreadthChange={setBreadth}
        setUnit={setUnit}  // Pass the unit state down to DimensionsInput
        setOriginalLength={setOriginalLength} // Pass setOriginalLength to receive the original length
        setOriginalBreadth={setOriginalBreadth}
        baffleSpacing={baffleSpacing}
        setBaffleSpacing={setBaffleSpacing}
      />
          
          
          {/* {product === 'Baffle' && (
            <label>
              Center To Center(CTC) Space {unit}<span style={{ color: '#BE1E2D' }}>*</span>:
              <input
                type='number'
                value={baffleSpacing}
                onChange={(e) => setBaffleSpacing(e.target.value)}
              />
            </label>
          )}  */}
         
         
          {/* <button onClick={handleGenerateAndExport}>Get Your Quotation</button> */}
          {/* <button onClick={handleExportToPDF} disabled={!result}>Export to PDF</button> */}
        </div>
        
      </div>
      <WallInput
        onWallsChange={handleWallsChange}
        productsConfig={productsConfig}
        shades
        unit={unit}
      />
       {showErrorMessage && (
          <p style={{ color: '#BE1E2D', fontStyle:'bold', marginTop: '10px', marginBottom: '5px' }}>
            **Please fill all the necessary fields.
          </p>
        )}
      <button onClick={handleGenerateAndExport}>Get Your Quotation</button>
      
       {/* Dimension Input Results */}
      {/* {result?.initialResult && (
        <div className="results-section">
          <h2>Results</h2>
          <h3>Wall 1</h3>
          <TableComponent result={result.initialResult}
            product={product} 
            productsConfigInMM={productsConfigInMM} 
            selectedShade={selectedShade}
            length={originalLength}
            breadth={originalBreadth}
            orientation={orientation} 
            />
        </div>
      )} */}

      {/* Wall Input Results */}
      {/* {result?.wallResults.length > 0 && (
        <div className="results-section">
          {result.wallResults.map((wallResult, index) => (
            <div key={index}>
              <h3>Wall {index + 2}</h3>
              <TableComponent result={wallResult}
               product={wallResult.product} 
               productsConfigInMM={productsConfigInMM} 
               selectedShade={wallResult.selectedShade}
               length={wallResult.originalLength} // Use original length
               breadth={wallResult.originalBreadth} // Use original breadth
               orientation={wallResult.orientation} />
            </div>
          ))}
        </div>
      )} */}

      {/* Total Count Table */}
        {/* {result && result.wallResults.length > 0 && (
          <div className="results-section">
            <h2>Total Summary</h2>
            <table className="results-table">
              <thead>
                <tr>
                  <th>Total Panel Quantity Incl. All Walls</th>
                  <th>Total Trims & Baffle Caps Count Incl. All Walls</th>
                  <th>Total Estimated Cost of All Walls (Incl. GST)</th>
                </tr>
              </thead>
              <tbody>
                <tr> */}
                  {/* Display the total panel count */}
                  {/* <td>{result.totalPanelCount} Panels</td> */}

                  {/* Display both Baffle Caps and Trims when applicable */}
                  {/* <td>
                    {result.totalBaffleCaps > 0 && `${result.totalBaffleCaps || 0} Baffle Caps`}
                    {result.totalBaffleCaps > 0 && result.totalJTrims > 0 && `, `}
                    {result.totalJTrims > 0 && `${result.totalJTrims || 0} J-T  rims`}
                    {result.totalTTrims > 0 && `, ${result.totalTTrims || 0} T-Trims`}
                  </td> */}

                  {/* Display the total estimated cost
                  <td>Rs. {result.totalEstimatedCost.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )} */}

      <div className="important-note">
        <p>Important Note: This Calculator is for indicative purposes only. Please recheck the exact calculation with your installer/contractor.</p>
      </div>
    </div>
  );
};

export default Calculator;