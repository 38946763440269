import React, { useState, useEffect } from 'react';
import './WallInput.css'; // Import the CSS file
import './Calculator.css'
import ShadesDropdown from './ShadesPricing/ShadesDropdown';
import BaffleOptions from './ShadesPricing/CategoryDropdownForBaffle'; // Import the Baffle category dropdown component

const WallInput = ({ onWallsChange, productsConfig, unit }) => {
  const [walls, setWalls] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); 

  // Conversion functions
  const mmToFeet = (mm) => (mm / 304.8).toFixed(2);
  const feetToMM = (feet) => (feet * 304.8).toFixed(2);
  const inchesToMM = (inches) => (inches * 25.4).toFixed(2);
  const inchesToFeet = (inches) => (inches / 12).toFixed(2);

  // Add a new wall input
  const handleAddWall = () => {
    if (walls.length >= 4) {
      setErrorMessage('**You can add up to 5 walls only.');
      return;
    }
    const newWalls = [...walls, { length: '', breadth: '', convertedLength: '', convertedBreadth: '', orientation: '', product: '', shade: '', category: '', ctc: '', convertedBaffleSpacing: '' }];
    setWalls(newWalls);
    setErrorMessage('');
    onWallsChange(newWalls); // Notify parent of change after adding the wall
  };

  // Remove a specific wall input
  const handleRemoveWall = (index) => {
    const updatedWalls = walls.filter((_, i) => i !== index);
    setWalls(updatedWalls); 
    onWallsChange(updatedWalls); // Notify parent of change
    setErrorMessage('');
  };

  // Update the state when input changes
  const handleWallChange = (index, field, value) => {
    const updatedWalls = walls.map((wall, i) => (i === index ? { ...wall, [field]: value } : wall));
    setWalls(updatedWalls);
    onWallsChange(updatedWalls); // Notify parent of change
  };


  // useEffect to handle product-wise conversion for each wall
  useEffect(() => {
    const updatedWalls = walls.map((wall) => {
      let convertedLength = wall.length;
      let convertedBreadth = wall.breadth;
      let convertedBaffleSpacing = wall.baffleSpacing;

      if (wall.product === 'Soffit' || wall.product === 'Duo') {
        if (unit === 'MM') {
          convertedLength = mmToFeet(wall.length);
          convertedBreadth = mmToFeet(wall.breadth);
        } else if (unit === 'Inches') {
          convertedLength = inchesToFeet(wall.length);
          convertedBreadth = inchesToFeet(wall.breadth);
        }
      } else {
        if (unit === 'Feet') {
          convertedLength = feetToMM(wall.length);
          convertedBreadth = feetToMM(wall.breadth);
        } else if (unit === 'Inches') {
          convertedLength = inchesToMM(wall.length);
          convertedBreadth = inchesToMM(wall.breadth);
        }
      }

      // Handle baffle spacing conversion for Baffle product
      if (wall.product === 'Baffle') {
        if (unit === 'Feet') {
          convertedBaffleSpacing = feetToMM(wall.baffleSpacing);
        } else if (unit === 'Inches') {
          convertedBaffleSpacing = inchesToMM(wall.baffleSpacing);
        }
      }

      return {
        ...wall,
        convertedLength,
        convertedBreadth,
        convertedBaffleSpacing,
      };
    });

    setWalls(updatedWalls);
    onWallsChange(updatedWalls); // Notify parent of change
  }, [walls, walls.length]);

  return (
    <div>
      {walls.map((wall, index) => (
        <div
          key={index}
          className="wall-input-group"
        >
          <label>Wall {index + 2}</label>
          <div className="wall-input-fields">
            {/* Product Dropdown */}
            <select
              className="wall-input-select"
              value={wall.product}
              onChange={(e) => handleWallChange(index, 'product', e.target.value)}
            >
              <option value="">Select Product</option>
              {Object.keys(productsConfig).map((key) => (
                <option key={key} value={key}>
                  {productsConfig[key].name}
                </option>
              ))}
            </select>

            {/* Shades Dropdown */}
            {wall.product !== 'Baffle' && (
            <ShadesDropdown
              className="shades-dropdown-select"
              product={wall.product}
              selectedShade={wall.shade}
              onSelectShade={(shade) => handleWallChange(index, 'shade', shade)}
            />)}

            {/* Conditionally render BaffleOptions */}
            {wall.product === 'Baffle' && (
              <BaffleOptions
                selectedCategory={wall.category}
                onSelectCategory={(value) => handleWallChange(index, 'category', value)}
                selectedShade={wall.shade}
                onSelectShade={(value) => handleWallChange(index, 'shade', value)}
              />
            )}

            {/* Orientation Label and Radio Buttons */}
            <label className="orientation-label">Orientation</label>
            <div className="orientation-radio-group">
              <label className="radio-label">
                <input
                  type="radio"
                  value="Horizontal"
                  checked={wall.orientation === 'Horizontal'}
                  onChange={(e) => handleWallChange(index, 'orientation', e.target.value)}
                />
                Horizontal
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  value="Vertical"
                  checked={wall.orientation === 'Vertical'}
                  onChange={(e) => handleWallChange(index, 'orientation', e.target.value)}
                />
                Vertical
              </label>
            </div>

            {/* Width and Length Inputs in a Row */}
            <div className="wall-input-row">
              <input
                type="number"
                className="wall-input"
                placeholder={`Width (${unit})`} // Show unit in placeholder
                value={wall.length}
                onChange={(e) => handleWallChange(index, 'length', e.target.value)}
              />
              <input
                type="number"
                className="wall-input"
                placeholder={`Length (${unit})`} // Show unit in placeholder
                value={wall.breadth}
                onChange={(e) => handleWallChange(index, 'breadth', e.target.value)}
              />
            </div>

             {/* CTC Input for Baffle */}
             {wall.product === 'Baffle' && (
              <div className="wall-input-row">
                <input
                  type="number"
                  className="wall-input"
                  placeholder={`CTC (${unit})`} // Show unit in placeholder
                  value={wall.baffleSpacing}
                  onChange={(e) => handleWallChange(index, 'baffleSpacing', e.target.value)}
                />
              </div>
            )}

            {/* Remove Wall Button */}
            <a className="remove-wall-button" onClick={() => handleRemoveWall(index)}>
              <span className="minus-icon">-</span>
              Remove Wall
            </a>
          </div>
        </div>
      ))}
      <a className="add-wall-button" onClick={handleAddWall}>
        <span className="plus-icon">+</span>
        {walls.length === 0 ? 'ADD WALL' : 'ADD ANOTHER WALL'}
      </a>

       {/* Error Message if user exceeds wall limit */}
       {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default WallInput;
