export const BafflehorizontalPanelCountInMM = (length, breadth, panelLength, Space) => {
  // var sumOfSpacing = (Space * 2);
  // console.log("Spacing Total: " +sumOfSpacing)
  // var CTC = sumOfSpacing + panelBreadth;
  // console.log("Center to center: " +CTC);
  // console.log("Length in Baffle: "+length)
  // console.log("Breadth in Baffle: "+breadth)
  console.log("Baffle Spacing: "+Space);
  // console.log("Baffle Pannel Length: "+panelLength);
  

  if(length <= 3060){
    if (length <= 1530 && breadth <= 1530) {
      const verticalPanelCount = Math.ceil(breadth/Space);
      // console.log("Vertical Panel Count: " +verticalPanelCount)
      const cuttingPanel = Math.floor(panelLength/length);
      // console.log("Cutting Panel: "+cuttingPanel)
      const totalPanelReruired = Math.ceil(verticalPanelCount/cuttingPanel);
      // console.log("Total Panel Required: "+totalPanelReruired)
      return totalPanelReruired;
    }
    else {
      const initialPanelRequired = breadth / Space;
      const cuttingPanel = Math.floor(panelLength / length);
      return Math.ceil(initialPanelRequired / cuttingPanel);
    }
  }else{
    // console.log("Length: "+length);
    // console.log("Breadth: "+ breadth);
    // console.log("Panel Length: "+panelLength);
    // console.log("Panel Breadth: "+panelBreadth);
    // console.log("Space: " +Space);
    const fullHorizontalItems = Math.floor(length / panelLength);
    // console.log("Full Horizontal Items: " +fullHorizontalItems);
    const fullVerticalItems = Math.ceil(breadth / Space);
    // console.log("Full Vertical Items: " +fullVerticalItems)
    const fullpanelLengths = fullHorizontalItems * fullVerticalItems;
    
    // Handle the remaining length
    const remainingLength = Math.ceil(length % panelLength);
    // console.log("Remaining Length: " +remainingLength);
    let additionalpanelLengths = 0;
    if (remainingLength > 0 && remainingLength <= 1530) {
      const cuttingPanelWithRemainingLength = Math.floor(panelLength/remainingLength)
      const dividelenghtItems = Math.ceil(breadth/Space);
      additionalpanelLengths =  Math.ceil(dividelenghtItems/cuttingPanelWithRemainingLength);
    }else if(remainingLength > 1530){
      additionalpanelLengths = Math.ceil(breadth/Space);
    }
    // console.log("Additional Panel Length: " +additionalpanelLengths);
    const totalItems = fullpanelLengths + Number(additionalpanelLengths);
    return totalItems;
    }
  }



export const BaffleverticalPanelCountInMM = (length, breadth, panelLength, Space) => {
// var sumOfSpacing = (Space * 2);
// console.log("Spacing Total: " +sumOfSpacing)
// var CTC = sumOfSpacing + panelBreadth;
// console.log("Center to center: " +CTC);
// console.log("Entered in Baffle Vertical Orientation")

if(breadth <= 3060){
  if (length <= 1530 && breadth <= 1530) {
    const horizontalPanelCount = Math.ceil(length/Space);
    // console.log("Vertical Panel Count: " +horizontalPanelCount)
    const cuttingPanel = Math.floor(panelLength/breadth);
    // console.log("Cutting Panel: "+cuttingPanel)
    const totalPanelReruired = Math.ceil(horizontalPanelCount/cuttingPanel);
    // console.log("Total Panel Required: "+totalPanelReruired)
    return totalPanelReruired;
  }
  else {
    const initialPanelRequired = length / Space;
    // console.log("Initial Panel required: "+ initialPanelRequired)
    const cuttingPanel = Math.floor(panelLength / breadth);
    // console.log("Cutting Panel: " + cuttingPanel);
    return Math.ceil(initialPanelRequired / cuttingPanel);
  }
  }else{
        // console.log("Length: "+length);
        // console.log("Breadth: "+ breadth);
        // console.log("Panel Length: "+panelLength);
        // console.log("Panel Breadth: "+panelBreadth);
        // console.log("Space: " +Space);
        const fullHorizontalItems = Math.floor(breadth / panelLength);
        // console.log("Full Horizontal Items: " +fullHorizontalItems);
        const fullVerticalItems = Math.ceil(length / Space);
        // console.log("Full Vertical Items: " +fullVerticalItems)
        const fullpanelLengths = fullHorizontalItems * fullVerticalItems;
        
        // Handle the remaining length
        const remainingLength = Math.ceil(breadth % panelLength);
        // console.log("Remaining Length: " +remainingLength);
        let additionalpanelLengths = 0;
        if (remainingLength > 0 && remainingLength <= 1530) {
          const cuttingPanelWithRemainingLength = Math.floor(panelLength/remainingLength)
          const dividelenghtItems = Math.ceil(length/Space);
          additionalpanelLengths =  Math.ceil(dividelenghtItems/cuttingPanelWithRemainingLength);
        }else if(remainingLength > 1530){
          additionalpanelLengths = Math.ceil(length/Space);
        }
        // console.log("Additional Panel Length: " +additionalpanelLengths);
        const totalItems = fullpanelLengths + Number(additionalpanelLengths);
        return totalItems;
        }
      }

// export const BafflehorizontalPanelCountInMM = (length, breadth, panelLength, panelBreadth, Space) => {
//     var sumOfSpacing = (Space * 2);
//     console.log("Spacing Total: " +sumOfSpacing)
//     var CTC = sumOfSpacing + panelBreadth;
//     console.log("Center to center: " +CTC);

//     if(length <= 3060){
//       if (length <= 1530 && breadth <= 1530) {
//         const verticalPanelCount = Math.ceil(breadth/CTC);
//         console.log("Vertical Panel Count: " +verticalPanelCount)
//         const cuttingPanel = Math.floor(panelLength/length);
//         console.log("Cutting Panel: "+cuttingPanel)
//         const totalPanelReruired = Math.ceil(verticalPanelCount/cuttingPanel);
//         console.log("Total Panel Required: "+totalPanelReruired)
//         return totalPanelReruired;
//       }
//       else {
//         const initialPanelRequired = breadth / CTC;
//         const cuttingPanel = Math.floor(panelLength / length);
//         return Math.ceil(initialPanelRequired / cuttingPanel);
//     }
//   }else{
//       console.log("Length: "+length);
//       console.log("Breadth: "+ breadth);
//       console.log("Panel Length: "+panelLength);
//       console.log("Panel Breadth: "+panelBreadth);
//       console.log("Space: " +Space);
//       const fullHorizontalItems = Math.floor(length / panelLength);
//       console.log("Full Horizontal Items: " +fullHorizontalItems);
//       const fullVerticalItems = Math.ceil(breadth / CTC);
//       console.log("Full Vertical Items: " +fullVerticalItems)
//       const fullpanelLengths = fullHorizontalItems * fullVerticalItems;
      
//       // Handle the remaining length
//       const remainingLength = Math.ceil(length % panelLength);
//       console.log("Remaining Length: " +remainingLength);
//       let additionalpanelLengths = 0;
//       if (remainingLength > 0 && remainingLength <= 1530) {
//         const cuttingPanelWithRemainingLength = Math.floor(panelLength/remainingLength)
//         const dividelenghtItems = Math.ceil(breadth/panelBreadth);
//         additionalpanelLengths =  Math.ceil(dividelenghtItems/cuttingPanelWithRemainingLength);
//       }else if(remainingLength > 1530){
//         additionalpanelLengths = Math.ceil(breadth/CTC);
//       }
//       console.log("Additional Panel Length: " +additionalpanelLengths);
//       const totalItems = fullpanelLengths + Number(additionalpanelLengths);
//       return totalItems;
//       }
//     }



// export const BaffleverticalPanelCountInMM = (length, breadth, panelLength, panelBreadth, Space) => {
//   var sumOfSpacing = (Space * 2);
//   console.log("Spacing Total: " +sumOfSpacing)
//   var CTC = sumOfSpacing + panelBreadth;
//   console.log("Center to center: " +CTC);

//   if(length <= 3060){
//     if (length <= 1530 && breadth <= 1530) {
//       const horizontalPanelCount = Math.ceil(length/CTC);
//       console.log("Vertical Panel Count: " +horizontalPanelCount)
//       const cuttingPanel = Math.floor(panelLength/breadth);
//       console.log("Cutting Panel: "+cuttingPanel)
//       const totalPanelReruired = Math.ceil(horizontalPanelCount/cuttingPanel);
//       console.log("Total Panel Required: "+totalPanelReruired)
//       return totalPanelReruired;
//     }
//         else {
//             const initialPanelRequired = length / panelBreadth;
//             const cuttingPanel = Math.floor(panelLength / breadth);
//             return Math.ceil(initialPanelRequired / cuttingPanel);
//         }
//     }else{
//           console.log("Length: "+length);
//           console.log("Breadth: "+ breadth);
//           console.log("Panel Length: "+panelLength);
//           console.log("Panel Breadth: "+panelBreadth);
//           console.log("Space: " +Space);
//           const fullHorizontalItems = Math.floor(breadth / panelLength);
//           console.log("Full Horizontal Items: " +fullHorizontalItems);
//           const fullVerticalItems = Math.ceil(length / CTC);
//           console.log("Full Vertical Items: " +fullVerticalItems)
//           const fullpanelLengths = fullHorizontalItems * fullVerticalItems;
          
//           // Handle the remaining length
//           const remainingLength = Math.ceil(breadth % panelLength);
//           console.log("Remaining Length: " +remainingLength);
//           let additionalpanelLengths = 0;
//           if (remainingLength > 0 && remainingLength <= 1530) {
//             const cuttingPanelWithRemainingLength = Math.floor(panelLength/remainingLength)
//             const dividelenghtItems = Math.ceil(length/panelBreadth);
//             additionalpanelLengths =  Math.ceil(dividelenghtItems/cuttingPanelWithRemainingLength);
//           }else if(remainingLength > 1530){
//             additionalpanelLengths = Math.ceil(length/CTC);
//           }
//           console.log("Additional Panel Length: " +additionalpanelLengths);
//           const totalItems = fullpanelLengths + Number(additionalpanelLengths);
//           return totalItems;
//           }
//         }