import React from 'react';

const BaffleOptions = ({ selectedCategory, onSelectCategory, selectedShade, onSelectShade, selectedBottomShade, onSelectBottomShade }) => {
  const categories = [
    { name: 'Baffle R50-S' },
    { name: 'Baffle R50-M' },
    { name: 'Baffle R50-L' }
  ];

  const shadesByCategory = {
    'Baffle R50-S': [
      { name: 'Royal Nut', price: 140, finishingCapPrice: 28 },
      { name: 'Oak Wood Ocre', price: 140, finishingCapPrice: 28 },
      { name: 'Noce Grande', price: 140, finishingCapPrice: 28 },
      { name: 'Gold Maple', price: 140, finishingCapPrice: 28 },
      { name: 'Honey Maple', price: 140, finishingCapPrice: 28 },
      { name: 'Linen Grey', price: 140, finishingCapPrice: 28 },
      { name: 'Super White', price: 140, finishingCapPrice: 28 },
      { name: 'Graphite', price: 140, finishingCapPrice: 28 },
    ],
    'Baffle R50-M': [
      { name: 'Royal Nut', price: 180, finishingCapPrice: 42 },
      { name: 'Oak Wood Ocre', price: 180, finishingCapPrice: 42 },
      { name: 'Noce Grande', price: 180, finishingCapPrice: 42 },
      { name: 'Gold Maple', price: 180, finishingCapPrice: 42 },
      { name: 'Honey Maple', price: 180, finishingCapPrice: 42 },
      { name: 'Linen Grey', price: 180, finishingCapPrice: 42 },
      { name: 'Super White', price: 180, finishingCapPrice: 42 },
      { name: 'Graphite', price: 180, finishingCapPrice: 42 },
    ],
    'Baffle R50-L': [
      { name: 'Royal Nut', price: 210, finishingCapPrice: 54 },
      { name: 'Oak Wood Ocre', price: 210, finishingCapPrice: 54 },
      { name: 'Noce Grande', price: 210, finishingCapPrice:54 },
      { name: 'Gold Maple', price: 210, finishingCapPrice: 54 },
      { name: 'Honey Maple', price: 210, finishingCapPrice: 54 },
      { name: 'Linen Grey', price: 210, finishingCapPrice: 54 },
      { name: 'Super White', price: 210, finishingCapPrice: 54 },
      { name: 'Graphite', price: 210, finishingCapPrice: 54 },
    ],
  };

  // const bottomShades = [
  //   { name: 'Black', price: 50 },
  //   { name: 'Royal Nut', price: 64 },
  //   { name: 'Oak Wood Ocre', price: 64 },
  //   { name: 'Noce Grande', price: 64 },
  //   { name: 'Gold Maple', price: 64 },
  //   { name: 'Honey Maple', price: 64 },
  //   { name: 'Linen Grey', price: 64 },
  //   { name: 'Super White', price: 64 },
  //   { name: 'Graphite', price: 64 },
  // ];

  const shades = shadesByCategory[selectedCategory] || [];

  return (
    <div className="baffle-options">
      {/* Category Dropdown */}
      <div className="customer-detail">
        <label>Category<span style={{ color: '#BE1E2D' }}>*</span>:</label>
        <select
          value={selectedCategory}
          onChange={(e) => onSelectCategory(e.target.value)}
        >
          <option value="">Select Category</option>
          {categories.map((category, index) => (
            <option key={index} value={category.name}>
              {category.name}
            </option>
          ))} 
        </select>
      </div>

      {/* Shades Dropdown based on Category */}
      <div className="customer-detail ">
        <label>Shades<span style={{ color: '#BE1E2D' }}>*</span>:</label>
        <select
          value={selectedShade ? selectedShade.name : ''}
          onChange={(e) => {
            const selected = shades.find(shade => shade.name === e.target.value);
            onSelectShade(selected);  // Pass the entire shade object
          }}
          disabled={!selectedCategory} // Disable if no category is selected
        >
          <option value="">Select Shade</option>
          {shades.map((shade, index) => (
            <option key={index} value={shade.name}>
              {shade.name}
            </option>
          ))}
        </select>
      </div>

     {/* Bottom Shades Dropdown
     <div className="bottom-shades-dropdown">
        <label>Shades for Baffle Bottom<span style={{ color: '#BE1E2D' }}>*</span>:</label>
        <select
          value={selectedBottomShade ? selectedBottomShade.name : ''}
          onChange={(e) => {
            const selected = bottomShades.find(shade => shade.name === e.target.value);
            onSelectBottomShade(selected);  // Pass the entire bottom shade object
          }}
        >
          <option value="">Select Bottom Shade</option>
          {bottomShades.map((shade, index) => (
            <option key={index} value={shade.name}>
              {shade.name}
            </option>
          ))}
        </select>
      </div> */}
    </div>
  );
};

export default BaffleOptions;