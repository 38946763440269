export const calculateDuraTotalItemsHorizontal = (length, breadth, lengthItem, breadthItem) => {
  // console.log("Length of room: " +length);
  // console.log("Breadth of room: " +breadth);
  // console.log("Length Item: " +lengthItem);
  // console.log("Breadth Item: " +breadthItem);
  
  // console.log("Into Calculate Dura Total Items: " )
  if (length <= 3660) {
    if (length <= 1830 && breadth <= 1830) {
      return Math.ceil((length * breadth) / (lengthItem * breadthItem));
     }else {
      const initialPanelRequired = breadth / breadthItem;
      const cuttingPanel = Math.floor(lengthItem / length);
      return Math.ceil(initialPanelRequired / cuttingPanel);
    }
  }else{
      // console.log("Into The Dura Streta Panels");
      // console.log(length);
      const fullHorizontalItems = Math.floor(length / lengthItem);
      // console.log("Full Horizontal Items: "+fullHorizontalItems);
      const fullVerticalItems = Math.ceil(breadth / breadthItem);
      // console.log("Full Vertical Items: " +fullVerticalItems)
      const fullLengthItems = fullHorizontalItems * fullVerticalItems;
      // Handle the remaining length
      const remainingLength = Math.ceil(length % lengthItem);
      // console.log(remainingLength);
      let additionalLengthItems = 0;
      if (remainingLength > 0 && remainingLength < 1830) {
        const cuttingPanelWithRemainingLength = Math.floor(lengthItem/remainingLength)
        const dividelenghtItems = Math.floor(lengthItem/remainingLength);
        additionalLengthItems = (dividelenghtItems/cuttingPanelWithRemainingLength);
      }else if(remainingLength > 1830){
        additionalLengthItems = Math.ceil(breadth/breadthItem);
      }else if(remainingLength === 1830){ 
        additionalLengthItems = breadth/2;
      }
      const totalItems = fullLengthItems + Number(additionalLengthItems);
      return totalItems;
    }
  };
  
  export const calculateDuraTotalItemsVertical = (length, breadth, lengthItem, breadthItem) => {
    // console.log("Length IN Dura: " +length);
    // console.log("Breadth IN Dura: " +breadth);
    // console.log("Length Item IN Dura: " +lengthItem);
    // console.log("Breadth Item IN Dura: " +breadthItem);
    
    if (length <= 1830 && breadth <= 1830) {
        return Math.ceil((length * breadth) / (lengthItem * breadthItem));
    }else{
      const fullHorizontalItems = Math.floor(length / lengthItem);
      // console.log("Full Horizontal Items: " +fullHorizontalItems);

      const fullVerticalItems = Math.ceil(breadth / breadthItem);
      // console.log("Full Vertical Items: " +fullVerticalItems);

      const fullLengthItems = fullHorizontalItems * fullVerticalItems;
      // console.log("Full Length Items: " +fullLengthItems);
    
      // Handle the remaining length
      const remainingLength = Math.ceil(length % lengthItem);
      // console.log("Remaining Length: " +remainingLength);
      let additionalLengthItems = 0;
      if (remainingLength > 0 && remainingLength <= 1830) {
        const cuttingPanelWithRemainingLength = Math.floor(lengthItem/remainingLength)
        const dividelenghtItems = Math.floor(lengthItem/remainingLength);
        additionalLengthItems = (dividelenghtItems/cuttingPanelWithRemainingLength);
      }else if(remainingLength > 1830){
        additionalLengthItems = Math.ceil(breadth/breadthItem);
      }else if(remainingLength === 1830){
        additionalLengthItems = breadth/2;
      }
      const totalItems = fullLengthItems + Number(additionalLengthItems);
      return totalItems;
    }  
  };